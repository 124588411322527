'use client'

import { forwardRef, useState } from 'react'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/navigation'

import { ButtonV2, Modal } from '@fsg/gui-bits'

import { useAppContext } from './AppContext'

type Props = LinkProps & {
  href: string
  children?: React.ReactNode
  className?: string
}

export const AppLink = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const { href, children, className, ...linkProps } = props
  const router = useRouter()
  const { formRegistry } = useAppContext()

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const navigate = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    if (formRegistry.allowsNavigation()) {
      setShowConfirmModal(true)
    } else {
      router.push(href)
    }
  }

  const handleSaveClick = async () => {
    setShowConfirmModal(false)
    router.push(href)
  }

  const handleCancelClick = () => {
    setShowConfirmModal(false)
  }

  return (
    <>
      <Link className={className} href={href} onClick={navigate} {...linkProps} ref={ref}>
        {children}
      </Link>
      {showConfirmModal && (
        <Modal open={showConfirmModal} setOpen={setShowConfirmModal}>
          <Modal.Heading>Save Changes</Modal.Heading>
          <Modal.Description>You have unsaved changes. Are you sure you want to leave this page?</Modal.Description>
          <Modal.Actions>
            <ButtonV2 type="button" size="lg" variant="secondary" onClick={handleCancelClick}>
              Continue Editing
            </ButtonV2>
            <ButtonV2 type="button" size="lg" variant="primary" onClick={handleSaveClick}>
              Leave Page
            </ButtonV2>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
})

AppLink.displayName = 'AppLink'
