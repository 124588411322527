import styles from './LoadingBar.module.scss'

export function LoadingBar(props: any) {
  return (
    <div className="p-lg" {...props}>
      <div className={styles['loading-bar']}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
