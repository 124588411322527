'use client'

import React, { PropsWithChildren, useMemo } from 'react'

import { Modal, ModalProps } from '@fsg/gui-bits'

type RootModalContextType = {
  api: {
    showModal: () => void
    close: () => void
    setModalContent: (content: React.ReactNode) => void
    create: (props: ModalInstanceProps) => void
  }
}
const RootModalContext = React.createContext<RootModalContextType>(undefined)
RootModalContext.displayName = 'RootModalContext'

type ModalInstanceProps = Omit<ModalProps, 'open' | 'setOpen'>
export function RootModalProvider(props: PropsWithChildren) {
  const [open, setOpen] = React.useState<boolean>(false)
  const [modal, setModal] = React.useState<ModalInstanceProps | null>(null)
  const [modalContent, setModalContent] = React.useState<React.ReactNode>(null)

  const showModal = React.useCallback(() => setOpen(true), [])
  const close = React.useCallback(() => setOpen(false), [])
  const updateModalContent = React.useCallback((content: React.ReactNode) => setModalContent(content), [])

  const context = useMemo(
    () => ({
      api: {
        showModal,
        close,
        setModalContent: updateModalContent,
        create: (props: ModalInstanceProps) => setModal(props),
      },
    }),
    [showModal, close, updateModalContent],
  )

  return (
    <RootModalContext.Provider value={context}>
      {props.children}
      {modal && (
        <Modal open={open} setOpen={setOpen} {...modal}>
          {modalContent}
        </Modal>
      )}
    </RootModalContext.Provider>
  )
}

export function useRootModal() {
  const context = React.useContext(RootModalContext)

  if (context === undefined) throw new Error('Cannot call useRootModal outside of RootModalProvider')

  return context
}
