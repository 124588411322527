'use client'

import React, { useRef } from 'react'
import { usePathname } from 'next/navigation'

import { ButtonV2, Sheet, SheetContent, SheetTrigger } from '@fsg/gui-bits'
import { Hamburger } from '@fsg/icons'

import { useNavRoutes } from '@app/hooks'

import { Navbar } from './Navbar'
import { NavItemLink } from './NavItemLink'

export function MobileNavSheet() {
  const [isOpen, setIsOpen] = React.useState(false)
  const routes = useNavRoutes()
  const pathname = usePathname()
  const isFirstRender = useRef(true)

  React.useEffect(() => {
    // Only runs when pathname changes, not on first render
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    // Closes the sheet after a link is clicked, which redirects, meaning pathname changes
    setIsOpen(false)
  }, [pathname])

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <ButtonV2 className="px-1 py-1 lg:hidden" variant="ghost" onClick={() => setIsOpen((prev) => !prev)}>
          <Hamburger width="1.5em" height="1.5em" />
        </ButtonV2>
      </SheetTrigger>
      <SheetContent side="left">
        <div className="min-w-[200px]">
          <Navbar isOpen={isOpen} setIsOpen={setIsOpen}>
            {routes.map((props) => (
              <NavItemLink {...props} key={props.href} />
            ))}
          </Navbar>
        </div>
      </SheetContent>
    </Sheet>
  )
}
