'use client'

import React, { createContext, useState, useContext, ReactNode } from 'react';

const BarrelRollContext = createContext(undefined);

export const BarrelRollProvider = ({ children }: { children: ReactNode }) => {
  const [doBarrelRoll, setDoBarrelRoll] = useState(false);
  return (
    <BarrelRollContext.Provider value={{ doBarrelRoll, setDoBarrelRoll }}>
      {children}
    </BarrelRollContext.Provider>
  );
};

export const useBarrelRoll = () => {
  const context = useContext(BarrelRollContext);
  if (!context) {
    throw new Error('useBarrelRoll must be used within a BarrelRollProvider');
  }
  return context;
};
