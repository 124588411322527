'use client'

import React from 'react'
import classNames from 'classnames'
import { useSession } from 'next-auth/react'

import { Label } from '@fsg/gui-bits'
import { Logo } from '@fsg/svg'

import { NameBubble } from '@app/components/NameBubble'
import { OmniBox } from '@app/components/OmniBox'
import styles from '@app/styles/Header.module.scss'

import { MobileNavSheet } from './Navbar'

export function Header() {
  const { data: sessionData } = useSession()

  return (
    <header className={classNames('relative flex items-center justify-between border-off-white bg-white shadow', styles.root)}>
      <div className="flex items-center gap-3xs pr-lg md:w-[170px] lg:w-[225px]">
        <MobileNavSheet />
        <Logo width={49} height={24} />
        <Label className="!px-2xs">ENGAGE</Label>
      </div>
      <div className="flex-grow pr-lg">
        <OmniBox />
      </div>
      <div className="flex items-center gap-xl text-3xl">
        <NameBubble name={sessionData?.user?.name} />
      </div>
    </header>
  )
}
