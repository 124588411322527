'use client'

import React from 'react'
import { setChonkyDefaults } from 'chonky'
import { ChonkyIconFA } from 'chonky-icon-fontawesome'

export function ChonkyInit(): JSX.Element {
  setChonkyDefaults({ iconComponent: ChonkyIconFA as any })
  return null
}
