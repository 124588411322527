'use client'

import React, { Dispatch, SetStateAction, useContext } from 'react'

import { cn } from '@fsg/utils'

type NavbarContextValue = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const NavbarContext = React.createContext<NavbarContextValue | undefined>(undefined)

type NavbarProps = {
  className?: string
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export function Navbar(props: React.PropsWithChildren<NavbarProps>) {
  const { isOpen, setIsOpen, children } = props

  return (
    <NavbarContext.Provider value={{ isOpen, setIsOpen }}>
      <nav className={cn('transition-basic flex h-full min-w-0 flex-col gap-sm', props.className)}>{children}</nav>
    </NavbarContext.Provider>
  )
}

export function useNavbarContext() {
  const context = useContext(NavbarContext)
  if (context === undefined) {
    throw new Error('Cannot call useNavbarContext outside a NavbarContext provider.')
  }

  return context
}
