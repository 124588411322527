'use client'

import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import { Search, X } from '@fsg/design-system/svg/icons'
import { useRequestCallback } from '@fsg/next-auth/useRequest'

import { ENDPOINTS } from '../../lib/constants'
import { useBarrelRoll } from '../BarrellRoll/BarrellRollContext'
import styles from './OmniBox.module.scss'
import { EntityResponse, OmniBoxResults } from './OmniBox.types'
import { OmniBoxResultsBox } from './OmniBoxResults'
import { ContactResponse } from '@app/types'

export function OmniBox() {
  const [searchTerm, setSearchTerm] = useState('')
  const [results, setResults] = useState<OmniBoxResults>(null)
  const [loading, setLoading] = useState(false)
  const searchBox = useRef<HTMLInputElement>(null)
  const resultsRef = useRef(null)
  const [searchFocused, setSearchFocused] = useState(false)
  const { setDoBarrelRoll } = useBarrelRoll()

  const [recentSearches, setRecentSearches] = useState([])

  const { request } = useRequestCallback()

  const timeout = useRef(null)
  const abortController = useRef(new AbortController())

  useEffect(() => {
    const storedRecentSearches = localStorage.getItem('engageRecentSearches')
    if (storedRecentSearches) {
      setRecentSearches(JSON.parse(storedRecentSearches))
    }
  }, [])

  // focus on search box when ctrl/cmd + k is pressed
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        searchBox.current.focus()
      }
    }
    if (recentSearches.some((search: any) => typeof search === 'string')) {
      setRecentSearches([])
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const reset = (entity?: EntityResponse) => {
    if (entity) {
      addRecentSearch(entity)
    }
    setResults(null)
    setSearchTerm('')
    clearTimeout(timeout.current)
  }

  const addRecentSearch = (entity: EntityResponse) => {
    if (!entity) return
    recentSearches.unshift(entity)
    const uniqueSearches = [...new Set(recentSearches.map((x: any) => JSON.stringify(x)))].filter((search) => !!search)
    const limitedSearches = uniqueSearches.slice(0, 5).map((x: any) => JSON.parse(x))
    setRecentSearches(limitedSearches)
  }

  useEffect(() => {
    localStorage.setItem('engageRecentSearches', JSON.stringify(recentSearches))
  }, [recentSearches])

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      abortController.current.abort('unmounting')
      setSearchTerm('')
      setResults(null)
      searchBox.current?.blur()
    } else if (e.key === 'ArrowDown') {
      searchBox.current?.blur()

      resultsRef.current?.querySelector('a')?.focus()
    } else if (e.key === 'ArrowUp') {
      searchBox.current?.blur()

      const links = resultsRef.current?.querySelectorAll('a')
      links[links.length - 1]?.focus()
    } else if (e.key === 'Enter') {
      if (results) {
        const { leads, accounts, contacts, opportunities, sites } = results
        const allResults = [].concat(leads, accounts, contacts, opportunities, sites)
        if (allResults.length === 1) {
          resultsRef.current?.querySelector('a')?.click()
        }
      }
    }
  }

  useEffect(() => {
    setResults(null)
    clearTimeout(timeout.current)
    try {
      abortController.current.abort()
    } catch (e) { }
    abortController.current = new AbortController()

    if (searchTerm.length < 3) {
      setLoading(false)
      return
    }

    setLoading(true)
    timeout.current = setTimeout(async () => {
      const results = await request<any>({
        path: `${ENDPOINTS.MULTI_SEARCH}?q=${encodeURIComponent(searchTerm)}`,
        signal: abortController.current.signal,
      })
      const { leads, accounts, contacts, opportunities, sites } = results

      const dedupeContacts = contacts.filter((contact: ContactResponse) => contact.inactiveReason === null || contact.inactiveReason.reason !== 'Duplicate')

      setLoading(false)
      setResults({
        leads,
        accounts,
        contacts: dedupeContacts,
        opportunities,
        sites,
      })
    }, 300)

    return () => {
      clearTimeout(timeout.current)
      abortController.current.abort('unmounting')
    }
  }, [searchTerm])

  //stupid next hack
  const Transition = CSSTransition as any

  const fadeClassNames = {
    enter: styles['fade-enter'],
    enterActive: styles['fade-enter-active'],
    exit: styles['fade-exit'],
    exitActive: styles['fade-exit-active'],
  }
  const fadeSlideClassNames = {
    enter: styles['fade-slide-enter'],
    enterActive: styles['fade-slide-enter-active'],
    exit: styles['fade-slide-exit'],
    exitActive: styles['fade-slide-exit-active'],
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchTerm(value)
    if (value.toLowerCase() === 'do a barrel roll') {
      setDoBarrelRoll(true)
      setSearchTerm('')
      setTimeout(() => setDoBarrelRoll(false), 3000)
    }
  }

  return (
    <div className="relative max-w-[480px]">
      <div className={classNames('rounded-[20px flex w-full justify-center p-xs px-sm', styles['search-box-wrapper'])}>
        <Search width={20} height={20} />
        <input
          ref={searchBox}
          className={'w-full border-none bg-transparent p-0 pl-[10px] text-sm placeholder-gray-700 focus:outline-none'}
          autoComplete="off"
          value={searchTerm}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={() => setSearchFocused(true)}
          onBlur={() => setTimeout(() => setSearchFocused(false), 250)}
          placeholder="Search for people, leads, accounts, opportunities..."
        />
        {searchTerm.length > 0 && <X className="cursor-pointer" width={20} height={20} onClick={() => reset()} />}
      </div>
      <Transition in={searchTerm.length > 0} timeout={300} classNames={{ ...fadeClassNames }} unmountOnExit>
        <div className="fixed left-0 top-[50px] h-full w-full bg-gray-400 bg-opacity-50 backdrop-blur-sm z-max" onClick={() => reset()} />
      </Transition>
      <Transition
        in={searchTerm.length >= 3 || (searchFocused && recentSearches.length > 0)}
        timeout={300}
        classNames={{ ...fadeSlideClassNames }}
        unmountOnExit
      >
        <OmniBoxResultsBox
          ref={resultsRef}
          searchBoxRef={searchBox}
          results={results}
          reset={reset}
          loading={loading}
          recentSearches={recentSearches}
          searchTerm={searchTerm}
        />
      </Transition>
    </div>
  )
}
