'use client'

import React from 'react'

import { SessionProvider } from 'next-auth/react'

import { Session } from '@fsg/next-auth/types'

export interface AuthContextProps {
  children: React.ReactNode
  session: Session | null | undefined
}

export function AuthContext({ children, session }: AuthContextProps) {
  // console.debug(`session: ${session}`)
  // console.debug(`process.env.IDENTITY_BASE_URL: ${process.env.IDENTITY_BASE_URL}`)
  // console.debug(`process.env.IDENTITY_CLIENT_ID: ${process.env.IDENTITY_CLIENT_ID}`)
  return <SessionProvider session={session}>{children}</SessionProvider>
}
