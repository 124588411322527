'use client'

import { useEffect, useState } from 'react'

import { ButtonV2 } from '@fsg/gui-bits'
import { ChevronsLeft, ChevronsRight } from '@fsg/icons'
import { cn, isWindowWiderThan } from '@fsg/utils'

import { useNavRoutes } from '@app/hooks'

import { Navbar } from './Navbar'
import { NavItemLink } from './NavItemLink'

export function Sidebar() {
  const [isOpen, setIsOpen] = useState(false)
  const routes = useNavRoutes()

  useEffect(() => {
    if (!window) return
    setIsOpen(isWindowWiderThan('tablet'))
  }, [])

  const className = cn('transition-basic border-r border-gray-lightest bg-off-white h-full flex-col hidden lg:flex px-xs py-md overflow-hidden', {
    'w-[225px]': isOpen,
    'w-[56px]': !isOpen, // fixed width required for transition to work, 40px for nav item + 16px of padding
  })

  return (
    <aside className={className}>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen}>
        {routes.map((props) => (
          <NavItemLink {...props} key={props.href} />
        ))}
      </Navbar>
      <ButtonV2
        onClick={() => setIsOpen((prev) => !prev)}
        variant="ghost"
        size="lg"
        className="mt-auto self-start bg-gray-lightest px-3 text-gray-dark hover:text-color-primary"
      >
        {isOpen ? <ChevronsLeft /> : <ChevronsRight />}
      </ButtonV2>
    </aside>
  )
}
