'use client';

import React from 'react';
import { usePathname } from 'next/navigation';



import { cn } from '@fsg/utils'

import { AppLink } from '../AppLink'
import { useNavbarContext } from './Navbar'
import styles from './NavItem.module.scss'

interface NavItemLinkProps extends React.PropsWithChildren {
  className?: string
  href: string
  label: string
  icon: React.ElementType
  create?: React.ElementType
}

export function NavItemLink({ href, label, icon: Icon, create: Create }: NavItemLinkProps) {
  const { isOpen } = useNavbarContext()
  const pathname = usePathname()
  const isActive = href === '/' ? pathname === href : pathname.startsWith(href)

  return (
    <div className={cn(styles['nav-item__wrapper'], { [styles.isActive]: isActive })}>
      <AppLink
        prefetch={false}
        href={href}
        className={cn(styles['nav-item__link'], {
          [styles.isActive]: isActive,
          [styles.isOpen]: isOpen,
          [styles.fullRounded]: Create === undefined,
        })}
      >
        <Icon className={cn(styles['nav-item__icon'], { [styles.isOpen]: isOpen })} />
        <span className={cn(styles['nav-item__label'], { [styles.isOpen]: isOpen })}>{label}</span>
      </AppLink>
      {Create ? (
        <div className={cn(styles['nav-item__plus'], { [styles.isOpen]: isOpen })}>
          <Create navMode={true} buttonActive={isActive} />
        </div>
      ) : null}
    </div>
  )
}