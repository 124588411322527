'use client'

import React from 'react'
import Link from 'next/link'
import classNames from 'classnames'

import { ChevronRight, CircleDollarSign, ClipboardText, OfficeBuilding, Site, Users } from '@fsg/icons'

import { AccountResponse, ContactResponse, LeadResponse, OpportunityResponse, SiteResponse } from '../../../types'
import { EntityResponse } from './OmniBox.types'
import styles from './OmniBoxResultItems.module.scss'

class OmniBoxResultItemProps<T> {
  entity: T
  filtered?: boolean
  reset?: (term?: EntityResponse) => void
  handleKeyDown?: (e: React.KeyboardEvent, term?: string) => void
}

export function AccountListItem({ entity, reset, handleKeyDown, filtered }: OmniBoxResultItemProps<AccountResponse>) {
  let secondaryLine = []
  if (entity.erpId) {
    secondaryLine.push(entity.erpId)
  }
  if (entity.primaryAddress) {
    secondaryLine.push(`${entity.primaryAddress.line1}, ${entity.primaryAddress.city}, ${entity.primaryAddress.state}`)
  }
  if (entity.apContact) {
    secondaryLine.push(entity.apContact.fullName)
  }
  if (entity.primaryPhone) {
    secondaryLine.push(entity.primaryPhone.number)
  }

  return (
    <div className={styles['result-item']}>
      <Link onClick={() => reset(entity)} onKeyDown={(e) => handleKeyDown(e, entity.name)} href={`/accounts/${entity.id}/general/basic-information`}>
        <OfficeBuilding className="bg-red-100 text-red-800" />
        <div>
          <div className="font-bold">
            {entity.name}
            <span className={classNames(styles.tag, { [styles.filtered]: filtered })}>Account</span>
          </div>
          <div className={styles['secondary-line']}>{secondaryLine.join(' · ')}</div>
        </div>
        <ChevronRight />
      </Link>
    </div>
  )
}
export function LeadListItem({ entity, reset, handleKeyDown, filtered }: OmniBoxResultItemProps<LeadResponse>) {
  const secondaryLine = []
  if (entity.companyName) {
    secondaryLine.push(entity.companyName)
  }
  if (entity.primaryPhone) {
    secondaryLine.push(entity.primaryPhone.number)
  }
  if (entity.primaryAddress) {
    secondaryLine.push(`${entity.primaryAddress.line1}, ${entity.primaryAddress.city}, ${entity.primaryAddress.state}`)
  }

  return (
    <div className={styles['result-item']}>
      <Link onClick={() => reset(entity)} onKeyDown={(e) => handleKeyDown(e, entity.fullName)} href={`/leads/${entity.id}/general`}>
        <ClipboardText className="bg-blue-200 text-blue-800" />
        <div>
          <div className="font-bold">
            {entity.fullName}
            <span className={classNames(styles.tag, { [styles.filtered]: filtered })}>Lead</span>
          </div>
          <div className={styles['secondary-line']}>{secondaryLine.join(' · ')}</div>
        </div>
        <ChevronRight />
      </Link>
    </div>
  )
}
export function ContactListItem({ entity, reset, handleKeyDown, filtered }: OmniBoxResultItemProps<ContactResponse>) {
  const secondaryLine = []
  if (entity.currentRole) {
    secondaryLine.push(entity.currentRole.accountName)
  }
  if (entity.primaryPhone) {
    secondaryLine.push(entity.primaryPhone.number)
  }

  return (
    <div className={styles['result-item']}>
      <Link onClick={() => reset(entity)} onKeyDown={(e) => handleKeyDown(e, entity.fullName)} href={`/contacts/${entity.id}/basic-information`}>
        <Users className="bg-[#EDE9FE] text-[#492285]" />
        <div>
          <div className="font-bold">
            {entity.fullName}
            <span className={classNames(styles.tag, { [styles.filtered]: filtered })}>Contact</span>
          </div>
          <div className={styles['secondary-line']}>{secondaryLine.join(' · ')}</div>
        </div>
        <ChevronRight />
      </Link>
    </div>
  )
}
export function OpportunityListItem({ entity, reset, handleKeyDown, filtered }: OmniBoxResultItemProps<OpportunityResponse>) {
  const secondaryLine = []
  if (entity.account) {
    secondaryLine.push(entity.account.name)
  }
  if (entity.primaryStakeholder) {
    secondaryLine.push(entity.primaryStakeholder.fullName)
  }

  return (
    <div className={styles['result-item']}>
      <Link
        onClick={() => reset(entity)}
        onKeyDown={(e) => handleKeyDown(e, entity.name)}
        href={`/opportunities/${entity.id}/general/basic-information`}
      >
        <CircleDollarSign className="bg-[#D9F7FB] text-[#164E63]" />
        <div>
          <div className="font-bold">
            {entity.name}
            <span className={classNames(styles.tag, { [styles.filtered]: filtered })}>Opportunity</span>
          </div>
          <div className={styles['secondary-line']}>{secondaryLine.join(' · ')}</div>
        </div>
        <ChevronRight />
      </Link>
    </div>
  )
}
export function SiteListItem({ entity, reset, handleKeyDown, filtered }: OmniBoxResultItemProps<SiteResponse>) {
  const secondaryLine = []
  if (entity.owner) {
    secondaryLine.push(entity.owner.name)
  }
  if (entity.primaryAddress) {
    secondaryLine.push(`${entity.primaryAddress.line1}, ${entity.primaryAddress.city}, ${entity.primaryAddress.state}`)
  }
  return (
    <div className={styles['result-item']}>
      <Link onClick={() => reset(entity)} onKeyDown={(e) => handleKeyDown(e, entity.name)} href={`/sites/${entity.id}/general`}>
        <Site className="bg-yellow-100 text-yellow-800" />
        <div>
          <div className="font-bold">
            {entity.name}
            <span className={classNames(styles.tag, { [styles.filtered]: filtered })}>Site</span>
          </div>
          <div className={styles['secondary-line']}>{secondaryLine.join(' · ')}</div>
        </div>
        <ChevronRight />
      </Link>
    </div>
  )
}

export function ResultsList({ children }: { children: React.ReactNode }) {
  return <div>{children}</div>
}
