'use client'

import React from 'react'

import { cn } from '@fsg/utils'

import styles from './BarrellRoll/BarrellRoll.module.scss'
import { useBarrelRoll } from './BarrellRoll/BarrellRollContext'

export function MainBody({ children }: any) {
  const { doBarrelRoll } = useBarrelRoll()

  return (
    <main className={cn('overflow-y-auto pb-[60px]', { [styles['spin-animation']]: doBarrelRoll })} id="main-content">
      {children}
    </main>
  )
}
